import { useCancelRequestStore } from "~/store/cancelRequest";

export type FetchWithCancelResponse<T> = {
  data: T;
  cancel: () => void;
};

export async function fetchWithCancel<T>(
  url: string,
  forceRequest: boolean = false,
): Promise<FetchWithCancelResponse<T> | null> {
  const isServer = import.meta.server;
  if (isServer && forceRequest === false) {
    return null;
  }

  const abortController = isServer ? new AbortController() : useCancelRequestStore().getAbortController();

  const signal = abortController.signal;

  return {
    cancel: () => abortController.abort(),
    data: await $fetch<T>(url, { signal }),
  };
}
